'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { useTranslation } from '../i18n/client';

import BaseButton from '@/components/base/BaseButton/BaseButton';
import BaseIconButton from '@/components/base/BaseButton/BaseIconButton';
import NotFoundImage from '@/assets/images/not-found-image.png';

import { useSelector } from 'react-redux';
import { RootState } from '@/lib/store';
import { useCreateRecordMutation } from '@/lib/devops/recordAPI';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const { lang } = useParams();
  const { t } = useTranslation(lang, 'common');
  const router = useRouter();
  const pathname = usePathname();
  const user = useSelector((state: RootState) => state.user);
  const userAgent = navigator.userAgent;
  const [isCopied, setIsCopied] = useState(false);
  const [createRecord] = useCreateRecordMutation();

  useEffect(() => {
    createRecord({
      id: error?.digest,
      error: error?.message,
      stack: error?.stack,
      name: error?.name,
      datetime: new Date().toISOString(),
      user: user?.profile?.userId,
      pathname,
      userAgent,
    });
  }, [error]);

  const handleCopyCode = () => {
    if (error.digest) {
      navigator.clipboard.writeText(error.digest);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    }
  };

  const handleBackToHome = () => {
    router.push(`/${lang}/app/`);
  };

  return (
    <div className="flex h-full w-full flex-col items-center bg-primary-50 p-5 md:flex-row md:justify-center md:gap-5 md:p-20">
      <div className="flex flex-col gap-4 md:w-1/2 md:gap-10">
        <div className="flex flex-col gap-2 text-gray-900">
          <h1 className="text-3xl font-semibold lg:text-[64px] lg:font-bold lg:leading-[80px]">
            {t('utils.error.unexpectedTitle')}
          </h1>
          <p className="text-sm lg:text-lg">
            {t('utils.error.unexpectedDescription')}
          </p>
        </div>
        {error.digest && (
          <div className="mb-6 flex items-center justify-center gap-2">
            <p className="typo-paragraph-sm text-gray-700">
              {t('utils.error.errorCode')}: {error.digest}
            </p>
            <BaseIconButton
              name={isCopied ? 'CheckDefault' : 'CopyDefault'}
              onClick={handleCopyCode}
              variant="text"
              title={t('utils.error.copyCode')}
              size="s"
            />
          </div>
        )}
        <div className="flex items-center justify-end md:justify-start">
          <BaseButton variant="primary" onClick={handleBackToHome} size="l">
            <p className="typo-paragraph-sm text-white">
              {t('utils.error.backToHome')}
            </p>
          </BaseButton>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:max-w-[520px]">
        <Image src={NotFoundImage} alt="not found image" className="w-full" />
      </div>
    </div>
  );
}
